.snackbar {
  position: absolute;
  padding: 7px 15px;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.2s,
    transform 0.3s,
    opacity 0.3s;
  z-index: 10;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 250px;
  font-size: 1.1em;
  min-height: 30px;
  background: #f5f9f9;
  border-radius: 5px;
}

._bottom {
  transform: translateY(100%);
}

._top {
  transform: translateY(-100%);
}

._right {
  transform: translateX(-100%);
}

._left {
  transform: translateX(100%);
}

.snackbar_active {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.snackbar__container {
  display: flex;
  align-items: center;
  gap: 5px;
}
