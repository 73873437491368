.collection-chart__progress {
  display: flex;
  align-items: center;
  gap: 7px;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  right: 7px;
  padding: 6px 10px;
  background: #49494959;
  border-radius: 11px;
  color: #fff;
}

.collection-chart__progress-chart {
  width: 50px;
  height: 4px;
  border-radius: 10px;
  background-color: #ffeecfe0;
  position: relative;
}

.collection-chart__progress-done {
  background-color: #ffca58;
  height: 100%;
  border-radius: 10px;
  transition: var(--transition);
}

.collection-chart__progress > span {
  font-size: 0.8em;
}
