:root {
  --page-width: 98%;
  --header-font: 18px;
  --main-font: 16px;
  --header-font: 1.2em;
  --transition: all 0.3s;
  --main-gap: 5em;
  --bottom-gap: 4em;
  --top-gap: 35px;
  --light-grey: #bebebe;
  --black-secodary: #757575;
  --main-green: #127386;

  --prime-blue: #37636c;
  --darker-blue: #2e5760ab;
  --super-dark-blue: #264d56ab;
  --light-blue: #dbecec70;
  --prime-yellow: #ffd987;
  --light-green: #12738638;

  --super-light-green: #dbecec;
  --middle-green: #7da1a9;
  --hover-green: #bedcdf;
  --main-blue: #aacdd4;

  --light-red: #ff9671;
  --middle-red: #c06849;
  --white: #f5f9f9;
  --yellow: #fcc5546e;
  --lang-btn-height: 42px;
  --translate-radius: 10px;
  --heading-gap: 2em;
  --bck-svg-color: #dbecec70;
  --max-width: 1300px;
}

body {
  overflow-y: scroll;
}

.page {
  font-family: 'Noto Sans Display', Sans-serif, Arial, Helvetica;
  font-weight: 300;
  font-size: var(--main-font);
  /* overflow-x: hidden; */
}

.page__wrapper {
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page__loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--prime-blue);
  z-index: 100;
  display: flex;
}

.page__loading-overlay > .spinner {
  border-top: 3px solid var(--prime-yellow);
  border-right: 3px solid var(--prime-yellow);
}

/* .page__loading-overlay_active {
  display: flex;
} */

.page__loading-overlay_active > .spinner {
  border-right: 3px solid var(--prime-yellow);
  border-top: 3px solid var(--prime-yellow);
}

.heading2 {
  font-weight: 300;
  margin: 0 0 var(--heading-gap);
  font-size: 2em;
  color: var(--prime-blue);
}

.bck-svg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  height: 150px;
  width: 150px;
  fill: var(--light-blue);
}

.unscrolled {
  overflow-y: hidden;
}

@media (max-width: 580px) {
  .heading2 {
    font-size: 1.8em;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
