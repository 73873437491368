.sidenav {
  min-width: 220px;
  position: sticky;
  position: -webkit-sticky;
  top: 5px;
  height: 500px;
  display: block;
}

.sidenav > ul {
  padding: 0;
  /* min-width: 220px; */
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 310px;
  justify-content: space-around;
  box-shadow: 0px 0px 10px #1f383d78;
  background-color: var(--darker-blue);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.sidenav__link {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 1.1em;
  padding: 7px;
  transition: background-color 0.3s;
  border-radius: 5px;
  cursor: pointer;
}

.sidenav__link:hover,
.sidenav__link_active {
  background-color: var(--prime-blue);
}

.sidenav__link > svg {
  color: var(--main-blue);
  transition: color 0.3s;
}

.sidenav__link:hover > svg,
.sidenav__link_active > svg {
  color: var(--prime-yellow);
}

@media (max-width: 780px) {
  .sidenav {
    display: none;
    /* width: 100%;
    height: auto;
    position: fixed;
    bottom: 0;
    top: auto;
    z-index: 5;
    box-shadow: -1px -4px 4px #30303061; */
  }

  .sidenav > ul {
    min-height: auto;
    width: 100%;
    background-color: #315b64;
    flex-direction: row;
  }
}
