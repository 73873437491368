.words {
  max-width: var(--max-width);
  width: var(--page-width);
  padding-top: var(--main-gap);
  /* padding-bottom: var(--bottom-gap); */
  margin: auto;
  position: relative;
}

span.wtable__checkbox {
  &.Mui-checked {
    color: #7575759c;
  }
}

.words__container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8%;
  row-gap: 50px;
}

.words__table-wrapper {
  flex: 1 0 auto;
  position: relative;

  transition: opacity 0.8s 0.6s;
  opacity: 0;
}

.words__table-wrapper_active {
  opacity: 1;
}

.wtable {
  min-width: 400px;
}

.wtable th {
  text-align: left;
  padding: 10px;
  background-color: var(--white);
  font-weight: 300;
}

/* .wtable__th_btn {
  transition: var(--transition);
  cursor: pointer;
}

.wtable__th_btn:hover {
  background-color: var(--hover-green);
  border-radius: 10px;
} */

.wtable__th_checkbox > .languages-wrapper {
  min-height: auto;
  left: 0;
}

.wtable td {
  padding: 0 10px;
  height: 2.7em;
  font-size: 1.2em;
}

.wtable__td_emph {
  /* font-size: 1.1em; */
  color: var(--prime-blue);
  font-weight: 400;
}

.wtable__fr-btn {
  padding: 5px 13px;
  border-radius: 14px;
  background: var(--white);
  color: var(--prime-blue);
  font-size: 0.9em;
}

.wtable__fr-btn_high {
  background: #ffd9874d;
  color: #b87d00;
}

table tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

table tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.words__heading {
  margin-bottom: 3em;
}

.words__dis-cont {
  font-size: 1.5em;
  line-height: 1.7em;
  padding: 10px;
  border-bottom: 2px solid var(--prime-yellow);
  color: var(--prime-blue);

  position: absolute;
  top: 34px;
  right: 0;
  text-align: right;

  transition:
    opacity 0.6s 0.2s,
    transform 0.6s;
  transform: translateY(-100%);
  opacity: 0;
}

.words__dis-cont_active {
  transform: translateY(0);
  opacity: 1;
}

.words__filter-box {
  width: 270px;

  transition: opacity 0.8s 0.6s;
  opacity: 0;
}

.words__filter-box_active {
  opacity: 1;
}

.words__filter-cont {
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 1px #dfdfdf94;
  border-radius: 10px;
  /* max-width: 240px; */
}

.words__filters {
  margin-top: 2em;
}

.words__btn-cont {
  display: flex;
  gap: 15px;
  align-items: center;
  /*  margin-bottom: 2em; */
}

.words__btn-cont svg {
  width: 24px;
  height: 24px;
  color: var(--prime-blue);
  padding: 7px 7px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 1px #1273863b;
  display: block;
  transition: var(--transition);
}

.words__filter-icon {
  padding: 0;
  cursor: pointer;
  display: none;
}

.words__filter-icon:hover > svg {
  background-color: #fff;
}

.words__slider-wrapper {
  width: 100%;
  margin-top: 2em;
}

.words__heading-box {
  display: flex;
  align-items: center;
  /*  justify-content: space-between; */
  margin: 0 0 25px;
}

.words__heading-box > span {
  color: var(--prime-blue);
  font-size: 0.9em;
}

.words__slider-heading {
  margin: 0 5px 0 0;
  font-weight: 300;
  position: relative;
}

.words__fr-tlt {
  padding: 0 3px;
}

.words__fr-tlt > svg {
  display: block;
}

span.words__slider {
  color: var(--prime-blue);
}

.words__save-btn {
  position: relative;
}

.words__save-btn > svg {
  color: #757575;
}

.words__save-btn:hover {
  background: var(--hover-green);
}

.words__slider span {
  &.MuiSlider-thumb:hover,
  &.Mui-focusVisible {
    box-shadow: 0px 0px 0px 7px rgb(55 99 108 / 19%);
  }

  &.MuiSlider-valueLabel {
    background-color: transparent;
    color: var(--prime-blue);
    top: -3px;
  }

  &.MuiSlider-markLabel {
    font-size: 0.8em;
  }

  &.MuiSlider-markLabelActive {
    color: var(--prime-blue);
  }

  font-family: inherit;
}

div.words__select {
  width: 100%;
  /* margin-bottom: 30px; */
}

.words__select {
  & .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--prime-blue);
  }

  & .MuiInputLabel-root.Mui-focused {
    color: var(--prime-blue);
  }
}

.words__search-btn {
  background: var(--prime-blue);
  color: #fff;
  font-weight: 300;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  /* margin-bottom: 2em; */
  cursor: pointer;
  transition: var(--transition);
}

.words__search-btn:hover {
  background: var(--darker-blue);
}

.words__search-btn_inactive {
  background: var(--darker-blue);
  cursor: wait;
}

.words__table-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0);
  opacity: 0;
  transition:
    visibility 0.2s,
    opacity 0.3s;
  visibility: hidden;
}

.words__table-overlay_active {
  visibility: visible;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  opacity: 1;
}

.words__not-found {
  display: none;
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
  text-align: center;
}

.words__not-found_active {
  display: block;
}

.words__btn-box {
  gap: 15px;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;

  transition:
    transform 0.6s,
    opacity 0.6s;
  transform: translateY(100%);
  opacity: 0;
}

.words__btn-box_active {
  transform: translateY(0);
  opacity: 1;
}

.words__filter-btn {
  flex: 1;
  white-space: nowrap;
  font-weight: 300;
  border: 2px solid #c1c1c182;
  padding: 10px;
  border-radius: 20px;
  color: var(--black-secodary);
  font-size: 1.1em;
  transition: var(--transition);
  cursor: pointer;
}

.words__filter-btn:hover,
.words__filter-btn_active {
  /* background: #fee6b5; */
  color: #936400;
  border: 2px solid var(--prime-yellow);
}

.words__snack {
  position: fixed;
  bottom: 10%;
  box-shadow: 0px 0px 12px 1px #00000078;
}

@media (max-width: 900px) {
  .words__filter-icon {
    display: block;
  }

  .words__table-wrapper {
    width: 100%;
  }

  .words__filters {
    visibility: hidden;
    height: 0;
    min-height: 0;
    margin: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: all 0.3s ease-out;
  }

  .words__filters_active {
    visibility: visible;
    opacity: 1;
    margin-top: 2em;
    min-height: 300px;
    /* height: 100%; */
    transform: scaleY(1);
  }
}

@media (max-width: 768px) {
  .words__heading {
    margin-bottom: 5em;
  }

  .words__dis-cont {
    font-size: 1.3em;
    line-height: 1.4em;
    padding: 5px;

    top: 138px;
    right: -5px;
  }
}

@media (max-width: 580px) {
  .words__dis-cont {
    font-size: 1.1em;
    line-height: 1.4em;
  }

  .wtable td {
    font-size: 1em;
    height: 2.5em;
    padding: 0 8px;
  }

  .words__table-wrapper {
    overflow-x: auto;
  }
}

@media (max-width: 360px) {
  .words__dis-cont {
    font-size: 1em;
    line-height: 1.2em;
    text-align: start;
  }
}
