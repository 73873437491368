.auth {
  /* min-height: calc(100vh - 90px);
  background-color: var(--prime-blue); */
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  min-height: 468px;
}

.auth__form-wrapper {
  background: var(--darker-blue);
  color: #fff;
  /* border-radius: 10px; */
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 100%;
}

.auth__img-box {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.auth__img-box > svg {
  width: 30px;
  height: 30px;
}

.auth__img-box > h2 {
  margin: 0;
  font-weight: 300;
}

.auth__mode-btn {
  font-size: 1.1em;
  color: var(--prime-yellow);
  box-shadow: 0px 0px 12px 1px #334f55e6;
  padding: 5px 7px;
  border-radius: 10px;
  font-weight: 300;
  cursor: pointer;
  margin-left: 5px;
  transition: box-shadow 0.3s;
}

.auth__mode-btn:hover {
  box-shadow: 0px 0px 12px 1px #ffd98729;
}

.auth__form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  max-width: 300px;
}

.auth__input {
  & .MuiInputLabel-root {
    color: var(--light-grey);
  }

  & .MuiInput-input {
    font-size: 1.1em;
    color: #fff;
  }

  & .MuiInput-root:before {
    border-color: #bebebe7a;
  }

  & .MuiInput-root:after {
    border-color: var(--prime-yellow);
  }

  & .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 2px solid var(--light-grey);
  }

  & .MuiInputLabel-root.Mui-focused {
    color: var(--prime-yellow);
  }
}

.auth__input_error {
  & .MuiInput-root:before {
    border-color: #ff3a3a85;
  }
}

.auth__pass-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth__pass-btn {
  font-weight: 300;
  color: #fff;
  cursor: pointer;
  transition: var(--transition);
}

.auth__pass-btn > a {
  position: relative;
}

.auth__pass-btn > a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.5px;
  background-color: #fff;
}

.auth__pass-btn:hover {
  color: var(--prime-yellow);
}

.auth__checkbox {
  & .MuiFormControlLabel-label {
    font-weight: 300;
    font-family: inherit;
  }
  & .MuiCheckbox-root.Mui-checked {
    color: var(--prime-yellow);
  }

  & .MuiCheckbox-root {
    color: var(--light-grey);
  }
}

.auth__sbt-btn {
  background-color: var(--prime-yellow);
  font-weight: 400;
  font-family: inherit;
  border-radius: 10px;
  padding: 8px 5px;
  font-size: 1.1em;
  color: var(--prime-blue);
  transition: var(--transition);
  /* margin-top: 1em; */
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__sbt-btn_loading {
  opacity: 0.6;
}

.auth__sbt-btn_loading > .spinner {
  right: 20px;
  left: auto;
  width: 20px;
  height: 20px;
}

.auth__sbt-btn:not(.auth__sbt-btn_loading):hover,
.auth__sbt-btn:active {
  box-shadow: 0px 0px 20px 0px #ffd98755;
}

.auth__description {
  text-align: center;
}

.auth__err-box {
  /* display: none;
  padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff00001c;
  border-radius: 10px;
  border: 1px solid #ff3a3a85;
  font-size: 0.95em;
  line-height: 0.95em;
  box-sizing: border-box;

  height: 0;
  min-height: 0;
  padding: 0 10px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;

  transition: all 0.2s ease-out;
}

.auth__err-box_active {
  padding: 10px;
  min-height: 45px;
  height: auto;
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}

.auth__err-box > button {
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.auth__err-box > button:hover {
  background-color: #3939396b;
}

.auth__err-box > button > svg {
  width: 11px;
  opacity: 0;
}

.auth__err-box_active > button > svg {
  opacity: 1;
}

.auth__err-box > button > svg > line {
  stroke: #fff;
}

.auth__result-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border: 1px solid #179417;
  background-color: #056e0533;
  border-radius: 10px;
  box-sizing: border-box;

  height: 0;
  min-height: 0;
  padding: 0 10px;
  visibility: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;

  transition: all 0.2s ease-out;
}

.auth__result-box_active {
  padding: 10px;
  min-height: 45px;
  height: auto;
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}

.auth__err-text-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.auth__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #224e57f5;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-in 0.2s ease-out;
}

.auth__modal-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auth__modal-box p {
  font-size: 1.2em;
  color: #fff;
  padding: 5px;
  text-align: center;
}

.auth__modal-box p:first-of-type {
  margin-bottom: 20px;
}

.auth__modal-box .auth__mode-btn {
  font-size: 19px;
  margin-right: 5px;
  box-shadow: 0px 0px 12px 1px #25444a;
}

.auth__modal-box .auth__mode-btn:hover {
  box-shadow: 0px 0px 12px 1px #ffd98729;
}

.auth__close-btn {
  border: none;
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 50%;
  transition: var(--transition);
  position: absolute;
  top: 2%;
  right: 3%;
}

.auth__close-btn:hover {
  background: var(--darker-blue);
  cursor: pointer;
}

.auth__close-btn svg {
  display: block;
  transform: rotate(45deg);
  width: 21px;
}

.auth__close-btn svg line {
  stroke: #ffffff4f;
  stroke-width: 6;
}

/* thanks to https://codepen.io/petsto/pen/XaZRGZ */

.success-icon {
  display: inline-block;
  width: 8em;
  height: 8em;

  border-radius: 50%;
  border: 4px solid var(--prime-yellow);
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(0.7);
}

.success-icon {
  & .success-icon__tip,
  & .success-icon__long {
    display: block;
    position: absolute;
    height: 4px;
    background-color: var(--prime-yellow);
    border-radius: 10px;
  }

  & .success-icon__tip {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    transform: rotate(45deg);
    animation: tipInPlace 300ms ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: 180ms;
    visibility: hidden;
  }

  & .success-icon__long {
    width: 4em;
    transform: rotate(-45deg);
    top: 3.7em;
    left: 2.75em;
    animation: longInPlace 140ms ease-in-out;
    animation-fill-mode: forwards;
    visibility: hidden;
    animation-delay: calc(300ms + 140ms);
  }
}

@keyframes showSuccess {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0.7);
  }
}

@keyframes tipInPlace {
  from {
    width: 0em;
    top: 0em;
    left: -1.6em;
  }
  to {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    visibility: visible;
  }
}

@keyframes longInPlace {
  from {
    width: 0em;
    top: 5.1em;
    left: 3.2em;
  }
  to {
    width: 4em;
    top: 3.7em;
    left: 2.75em;
    visibility: visible;
  }
}

@media (max-width: 350px) {
  .auth__modal-box p {
    font-size: 1.1em;
  }

  .auth__modal-box .auth__mode-btn {
    font-size: 17px;
    margin-right: 5px;
    box-shadow: 0px 0px 12px 1px #25444a;
  }
}
