.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
  align-items: flex-end;
  justify-content: flex-end;
}

.dropdown {
  width: 100%;
}

.dropdown > ul {
  margin: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 310px;
  justify-content: space-around;
  box-shadow: 0px 0px 10px #1f383d78;
  background-color: #315b64;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}

.dropdown__link {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 1.1em;
  padding: 7px;
  transition: background-color 0.3s;
  border-radius: 5px;
  cursor: pointer;

  width: 130px;
}

.dropdown__link:hover,
.dropdown__link_active {
  background-color: var(--prime-blue);
}

.dropdown__link > svg {
  color: var(--main-blue);
  transition: color 0.3s;
}

.dropdown__link:hover > svg,
.dropdown__link_active > svg {
  color: var(--prime-yellow);
}

@media (max-width: 780px) {
  .dropdown-overlay {
    display: flex;
  }
}
