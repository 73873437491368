.translator-wrapper {
  max-width: var(--max-width);
  margin: auto;
  padding-top: var(--main-gap);
  position: relative;

  padding-bottom: var(--bottom-gap);
  /* min-height: 100vh; */
  box-sizing: border-box;
  width: var(--page-width);
  min-height: 600px;

  /* overflow: hidden; */
}

.translator-wrapper > h2 {
  /* font-weight: 400;
  margin: 0 0 var(--heading-gap); */
}

.translator {
  /* display: flex; */
  display: grid;
  grid-template-columns: minmax(300px, 1fr) auto minmax(300px, 1fr);
  /* align-items: center; */
  justify-content: center;
  gap: 8px;
  position: relative;
}

.translator__swap-btn {
  padding: 7px 0;
  cursor: pointer;
  height: auto;
  align-self: center;
  opacity: 0;

  transition: all 0.8s;
}

.translator_active > .translator__swap-btn {
  opacity: 1;
}

.translator__swap-btn > svg {
  fill: var(--middle-green);
  width: 30px;
  transition: var(--transition);
}

.translator__swap-btn:hover > svg {
  transform: rotateY(180deg);
  fill: var(--main-green);
}

.translator__swap-btn-mob {
  display: none;
}

.translator__box {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: var(--translate-radius);
}

.translator__box_input {
  background: var(--white);
  box-shadow: 0px 0px 10px 0px #89c4c45e;
  z-index: 1;

  transition:
    opacity 0.6s 0.2s,
    transform 0.6s;
  transform: translateX(-100%);
  opacity: 0;
}

.translator_active > .translator__box_input {
  transform: translateX(0);
  opacity: 1;
}

.translator__box_output {
  background: var(--super-light-green);
  box-shadow: 0px 0px 10px 0px #89c4c45e;

  transition:
    opacity 0.6s 0.2s,
    transform 0.6s;
  transform: translateX(100%);
  opacity: 0;
}

.translator_active > .translator__box_output {
  transform: translateX(0);
  opacity: 1;
}

.translator__lang {
  border-bottom: 1px solid #12738638;
  padding: 0;
  height: var(--lang-btn-height);
  box-sizing: border-box;
  cursor: pointer;
  transition: var(--transition);
  border-radius: var(--translate-radius) var(--translate-radius) 0 0;
}

.translator__lang:hover {
  background: var(--hover-green);
}

.translator__container {
  height: 170px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
  position: relative;
}

.translator__secondary-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.translator__secondary-container span {
  font-size: 0.9em;
  color: var(--black-secodary);
  margin: 0 7px;
}

.translator__freq-box {
  display: flex;
  align-items: flex-end;
  visibility: hidden;
}

.translator__freq-box_active {
  visibility: visible;
}

.translator__tlt-link {
  text-decoration: underline;
  transition: var(--transition);
}

.translator__tlt-link:hover {
  opacity: 0.6;
}

.translator__tlt-btn {
  padding: 3px 3px 0 3px;
  cursor: pointer;
}

.translator__tlt-btn > svg {
  display: block;
}

.translator__text {
  resize: none;
  outline: none;
  border: none;
  width: calc(100% - 50px);
  height: 75%;
  box-sizing: border-box;
  background: transparent;
  font-size: 1.4em;
  font-weight: 400;
  color: var(--prime-blue);
}

.translator__text_inactive {
  color: var(--black-secodary);
  font-weight: 300;
}

.translator__btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 10px;
  border-radius: 50%;
  transition: var(--transition);
  cursor: pointer;
}

.translator__btn > svg,
.translator__tlt-btn > svg {
  color: rgb(117, 117, 117);
}

.btn-cross {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.translator__btn:hover {
  background: var(--hover-green);
}

.btn-cross svg {
  display: block;
  transform: rotate(45deg);
  width: 17px;
}

.btn-cross svg line {
  stroke: var(--black-secodary);
  stroke-width: 8;
}

.error {
  bottom: 5px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 100%);
  -moz-transform: translate(-50%, 100%);
  padding: 5px;
  background: var(--light-red);
  border-radius: 10px;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: var(--transition);
}

.error_active {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
}

.translator__bck-svg {
}

.translator__snack {
  bottom: 4%;
  right: 4%;
  background-color: var(--prime-blue);
  max-width: 75%;
  color: #fff;
}

.translator__snack_err {
  background: #ff5353;
}

.translator__snack-text {
  line-height: 1.4em;
}

.translator__snack-text a {
  color: var(--prime-yellow);
  border-bottom: 1px solid var(--prime-yellow);
  font-weight: 400;
  transition: var(--transition);
}

.translator__snack-text a:hover {
  opacity: 0.8;
  border-bottom: none;
}

.translator__ya-link {
  position: absolute;
  right: 9px;
  bottom: -24px;
  z-index: 1;
  transition: opacity 0.3s;
}

.translator__ya-link:hover {
  opacity: 0.7;
}

@media (max-width: 950px) {
  .translator {
    grid-template-columns: minmax(290px, 700px);
    display: inline-grid;
  }

  .translator__container {
    height: 140px;
  }

  .translator__box_output {
  }

  .translator__swap-btn {
    display: none;
  }

  .translator__swap-btn-mob {
    display: block;
    position: absolute;
    bottom: -47px;
    z-index: 2;
    padding: 15px;
    right: 118px;
    cursor: pointer;
  }

  .translator__swap-btn-mob > svg {
    width: 22px;
    height: 22px;
    color: var(--prime-blue);
    padding: 15px;
    border-radius: 50%;
    transition: var(--transition);
    box-shadow: 0px 0px 20px 1px #1273863b;
    transform: rotate(90deg);
  }

  .translator__swap-btn-mob:active > svg {
    background-color: #fff;
  }
}

@media (max-width: 580px) {
  .translator__swap-btn-mob {
    right: 63px;
  }

  .translator__text {
    font-size: 1.3em;
  }
}
