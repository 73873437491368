.languages-wrapper {
  position: absolute;
  top: var(--lang-btn-height);
  box-sizing: border-box;
  width: 100%;
  /* visibility: hidden; */
  min-height: 600px;
  /* opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear; */
  z-index: 2;
}

/* .languages-wrapper_active {
  visibility: visible;
  opacity: 1;
} */

.languages {
  position: relative;
  background: var(--super-light-green);
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  min-height: 170px;
  transition: var(--transition);
  border-radius: 0 0 10px 10px;
}

.languages_input {
  background: var(--white);
}

.languages__search {
  width: 100%;
  box-sizing: border-box;
  border: none;
  line-height: 20px;
  border-radius: 6px;
  display: block;
  background: var(--white);
  outline: none;
  padding: 5px 10px;
  transition: var(--transition);
}

.languages__search_input {
  background: var(--super-light-green);
}

.languages__list {
  column-count: 4;
}

.languages__list_filtered {
  columns: 1;
}

.languages__item {
  line-height: 27px;
  display: flex;
  align-items: center;
  transition: var(--transition);
  cursor: pointer;
  border-radius: 5px;
}

.languages__item:hover {
  background: var(--hover-green);
}

.languages__item_active {
  background: var(--hover-green);
  color: var(--main-green);
}

.languages__mark {
  min-width: 31px;
  display: flex;
}

.languages__item svg {
  padding: 0 8px;
  visibility: hidden;
}

.languages__item_active svg {
  visibility: visible;
}

.languages__not-found {
  /* display: none; */
  position: absolute;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
  text-align: center;
  opacity: 1;
  animation: fade-in 0.3s;
}

.languages__comment {
  display: none;
  margin: 20px 0 10px;
  text-align: center;
  font-size: 0.9em;
}

.languages__comment_active {
  display: block;
}

@media (max-width: 950px) {
  .languages-wrapper {
    top: 0;
  }

  .languages {
    border-radius: 10px;
    top: 0;
    box-shadow: 0px 0px 10px 1px #37636c5e;
  }

  .languages__list {
    padding: 0;
    column-count: 3;
  }

  .languages__item {
    line-height: 30px;
  }
}

@media (max-width: 580px) {
  .languages__list {
    column-count: 2;
  }
}

@media (max-width: 370px) {
  .languages__list {
    font-size: 0.9em;
  }

  .languages__list {
    column-count: 1;
  }
}
