.dictionary {
  grid-column: 3;
  padding: 10px;
  visibility: hidden;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform-origin: top;
  -webkit-transform-origin: top;
  height: 0;
  transition:
    visibility 0.2s ease,
    transform 0.3s ease;
}

.dictionary_active {
  visibility: visible;
  transform: scaleY(1);
  height: auto;
  -webkit-transform: scaleY(1);
}

.dictionary_hidden {
  display: none;
}

.dictionary__heading {
  margin: 0;
  font-weight: 400;
  font-size: 1.25em;
}

.dictionary__translations {
  margin-top: 20px;
}

.dictionary__pos-card {
  margin-top: 13px;
}

.dictionary__pos {
  font-weight: 400;
  font-size: 1.1em;
  margin: 0px 0 7px 0;
}

.dictionary__ul {
  padding: 0;
  display: flex;
  margin: 0;
  flex-wrap: wrap;
}

.dictionary__item {
  margin: 0 5px 10px 5px;
  padding: 4px 7px;
  background: var(--super-light-green);
  border-radius: var(--translate-radius);
}

/* .table {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  justify-items: stretch;
  align-items: stretch;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
} */

table {
  border-spacing: 0;
  width: 100%;
}

th {
  font-weight: 400;
}

td {
  border-top: 1px solid #bebebe6e;
}

.table__data_transl {
  width: 50%;
}

.table__head-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__head-box > .table__tooltip {
  display: none;
}

.table__head-box > .table__tooltip_active {
  display: block;
}

.table__head-box > .table__tooltip_active > svg {
  color: #757575;
}

.table__block {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;
  padding: 8px;
  gap: 7px;
}

/* .table__block > span {
  padding: 3px 9px;
  border-radius: 10px;
  margin: 5px;
} */

.table__btn {
  padding: 4px 10px;
  border-radius: 10px;
  /* margin: 5px; */
  font-weight: 300;
  transition: var(--transition);
  cursor: pointer;
  text-align: center;
  width: min-content;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

div.table__btn-word > .table__btn_active {
  color: #fff;
  background-color: var(--darker-blue);
}

.table__btn-transl {
  background: var(--super-light-green);
}

.table__btn-transl:hover {
  background: #bbdcdc;
  color: var(--main-green);
}

div.table__btn-word > .table__btn-transl_active {
  background-color: var(--prime-yellow);
}

.table__btn-syn {
  background: var(--white);
}

.table__btn-syn:hover {
  background: #e2e9e9;
  color: var(--main-green);
}

.table__btn-syn_active {
  background-color: var(--main-blue);
}

.table__btn-word {
  position: relative;
}

.table__btn > span {
  font-size: 0.8em;
  margin-right: 2px;
}

.table__expand-btn {
  padding: 7px;
  border-radius: 10px;
  width: 100%;
  font-weight: 300;
  border: 1px solid #bebebe6e;
  transition: var(--transition);
  cursor: pointer;
}

.table__expand-btn_inactive {
  display: none;
}

.table__expand-btn:hover {
  background: var(--white);
}

.table__row {
  visibility: visible;
  transition:
    visibility 0.2s,
    transform 0.3s,
    opacity 0.3s;
  height: auto;
  /* transform: scaleY(1);
  -webkit-transform: scaleY(1); */
  transform-origin: top;
  -webkit-transform-origin: top;
  opacity: 1;
}

.table__row_hidden {
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  opacity: 0;
  visibility: collapse;
}

@media (max-width: 950px) {
  .dictionary {
    grid-column: auto;
  }
}

@media (max-width: 580px) {
  .dictionary {
    padding: 35px 0 0 0;
  }

  .table__btn {
    max-width: 135px;
  }

  .table__block {
    padding: 5px 5px 5px 0;
  }

  .dictionary__translations td:first-of-type,
  .dictionary__translations th:first-of-type {
    display: none;
  }
}
