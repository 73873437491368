.wordlist {
  /* flex: 1 0 auto; */
  position: relative;
  animation: fade-in 0.4s ease-out;

  width: 100%;
}

.wordlist-table {
  width: 100%;
  min-width: 300px;
  max-width: 900px;
  color: #fff;

  background-color: var(--darker-blue);
  border-radius: 15px;
  padding: 10px;

  table-layout: fixed;
}

.wordlist-table__row {
  opacity: 1;
  animation: fade-in 0.5s ease-out;
}

.wordlist-table__row_hidden {
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  opacity: 0;
  visibility: collapse;
}

.wordlist__btn-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  flex-wrap: wrap;
}

.wordlist__btn {
  padding: 5px 15px;
  color: #fff;
  font-weight: 300;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s;
  border: 1px solid var(--light-blue);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
}

.wordlist__btn > svg {
  z-index: 2;
  display: block;
  transform: rotate(45deg);
  width: 9px;
}

.wordlist__btn > .wordlist__btn-icon {
  display: block;
  transform: none;
  width: 15px;
  height: 15px;
}

.wordlist__btn > svg > line {
  stroke: #fff;
  stroke-width: 10px;
  transition: all 0.3s;
}

.wordlist__pgn-btn-box {
  display: none;
  justify-content: center;
  margin-top: 10px;
}

.wordlist__pgn-btn-box_active {
  display: flex;
}

.wordlist__pgn-btn {
  max-width: 600px;
  width: 100%;
  padding: 10px;
}

.wordlist__btn:hover {
  color: var(--prime-yellow);
  border: 1px solid var(--prime-yellow);
}

.wordlist__btn:hover > svg > line {
  stroke: var(--prime-yellow);
}

.wordlist__btn_active {
  color: var(--prime-yellow);
  border: 1px solid var(--prime-yellow);
}

.wordlist__btn_active > svg > line {
  stroke: var(--prime-yellow);
}

.wordlist-table th {
  padding: 10px 5px;
  font-weight: 300;
  text-align: left;
  color: var(--super-light-green);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #315b64f0;
  z-index: 2;
}

.wordlist-table th:first-of-type {
  width: 35px;
}

.wordlist-table__progress-head {
  display: flex;
  gap: 3px;
}

.wordlist-table tbody tr {
  background-color: var(--prime-blue);
}

.wordlist-table tbody tr:nth-of-type(even) {
  background-color: transparent;
}

.wordlist-table td {
  height: 2.5em;
  font-size: 1.1em;
  color: var(--super-light-green);
  border: none;
  overflow: hidden;

  padding: 1px 5px;
}

.wordlist-table td:not(.wordlist-table__td_tag) {
  text-overflow: ellipsis;
}

.wordlist-table__td_emph {
  font-weight: 400;
}

.wordlist-table tr td:first-of-type {
  border-radius: 15px 0 0 15px;
  padding-left: 5px;
}

.wordlist-table tr td:last-of-type {
  border-radius: 0 15px 15px 0;
}

.wordlist-table .wordlist__td_emph {
  color: #fff;
  font-weight: 400;
  font-size: 1.2em;
}

.wordlist-table__tag {
  border-radius: 10px;
  padding: 3px 8px;
  background-color: var(--bck-svg-color);
  color: var(--super-light-green);
  font-size: 0.9em;
  transition: background-color 0.3s;

  display: inline-block;
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a.wordlist-table__tag:hover {
  background-color: #8cb9b970;
}

.wordlist-table__progress {
  display: flex;
  align-items: center;
  gap: 7px;
}

.wordlist-table__progress-chart {
  width: 55px;
  height: 5px;
  border-radius: 10px;
  background-color: #ffd98726;
  position: relative;
}

.wordlist-table__progress-done {
  background: var(--prime-yellow);
  height: 100%;
  border-radius: 10px;
  transition: var(--transition);
}

.wordlist-table__progress > span {
  /* position: absolute;
  right: -10px; */
  font-size: 0.8em;
}

.wordlist-table__checkbox:hover {
  background-color: #ffd9871a;
}

.wordlist-table__checkbox > svg {
  color: var(--prime-yellow);
  width: 16px;
  height: 16px;
}

@media (max-width: 580px) {
  .wordlist-table {
    padding: 5px;
  }

  .wordlist-table td {
    font-size: 0.9em;
  }

  .wordlist-table__td_tag {
    display: none;
  }

  .wordlist-table th {
    font-size: 0.9em;
  }

  .wordlist-table__th_tag {
    display: none;
  }

  .wordlist-table__checkbox > svg {
    width: 14px;
    height: 14px;
  }

  .wordlist-table__progress {
    gap: 4px;
  }

  .wordlist-table__progress-chart {
    width: 45px;
  }

  .wordlist-table__progress > span {
    font-size: 0.8em;
  }

  .wordlist__btn {
    font-size: 1em;
  }
}
