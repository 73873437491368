.error-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.error-popup__snack {
  bottom: 5%;
  right: 5%;
  background-color: var(--middle-red);
  box-shadow: 0px 0px 11px #20383d;
  color: #fff;
  max-width: 300px;
  width: 100%;
}
