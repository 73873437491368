.frequency-wrapper {
  background: var(--prime-blue);
  overflow: hidden;
}

.frequency {
  max-width: var(--max-width);
  width: var(--page-width);
  /* margin: 0 auto var(--main-gap); */
  padding-top: var(--main-gap);
  padding-bottom: var(--bottom-gap);
  margin: auto;
  position: relative;
}

.frequency__heading {
  color: #fff;
}

.frequency__input-box {
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.8s ease-out;
  position: relative;
}

.frequency__input-box_active {
  opacity: 1;
}

.frequency__input input {
  font-size: 1.15em;
}

.frequency__input {
  & label.Mui-focused {
    color: var(--prime-blue);
  }
  & .MuiFilledInput-root:after {
    border-bottom-color: var(--prime-yellow);
    border-bottom-width: 2px;
  }
  & .MuiFilledInput-root {
    background-color: var(--white);
  }
  & .MuiFilledInput-root.Mui-focused {
    background-color: var(--super-light-green);
  }
}

.frequency__input:hover {
  & .MuiFilledInput-root {
    background-color: var(--super-light-green);
  }
}

.frequency__input > .spinner {
  right: -46px;
  left: auto;

  border-right: 3px solid var(--prime-yellow);
  border-top: 3px solid var(--prime-yellow);
}

.frequency__card-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  gap: 35px;
  visibility: hidden;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transform-origin: left;
  -webkit-transform-origin: left;
  opacity: 0;
  transition: all 0.4s ease-out;
}

.frequency__card-box_active {
  visibility: visible;
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  opacity: 1;
}

.frequency__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-right: 35px; */
  background: var(--darker-blue);
  color: #fff;
  max-width: 300px;
  min-width: 200px;
  min-height: 140px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  flex: 1;
}

.frequency__card:last-of-type {
  margin-right: 0;
}

.frequency__value {
  margin: 0px 0 10px;
  font-weight: 300;
  font-size: 1.8em;
  transition: var(--transition);
}

.frequency__value_loading {
  opacity: 0.1;
}

.frequency__value > span {
  font-size: 16px;
  font-weight: 300;
}

.frequency__card > p {
  font-size: 0.9em;
}

.frequency__tlt-link {
  text-decoration: underline;
  transition: var(--transition);
}

.frequency__tlt-link:hover {
  opacity: 0.6;
}

.frequency__tlt {
  position: absolute;
  top: 7px;
  right: 7px;
}

.frequency__tlt > svg {
  color: var(--super-light-green);
}

.frequency__not-found {
  display: none;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -50px;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
  text-align: center;
  color: #fff;
}

.frequency__not-found_active {
  display: block;
}

.frequency__lst-btn {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  transition: var(--transition);
  cursor: pointer;
  visibility: hidden;
  color: var(--light-grey);
  opacity: 0;
  transition:
    visibility 0.2s ease,
    opacity 0.3s,
    background 0.3s;
}

.frequency__lst-btn:hover {
  background: var(--darker-blue);
}

.frequency__lst-btn_active {
  visibility: visible;
  opacity: 1;
}

.frequency__bck-svg {
  z-index: 0;
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.frequency__bck-svg_active {
  opacity: 0.2;
}

.frequency__svg {
  color: var(--super-light-green);
}

.frequency__snack {
  bottom: -96px;
  right: 1%;
  background-color: var(--prime-yellow);
  max-width: 380px;
  color: #000;
}

.frequency__snack-text a {
  color: var(--prime-blue);
  border-bottom: 1px solid var(--prime-blue);
  font-weight: 400;
  transition: var(--transition);
}

.frequency__snack-text a:hover {
  border-bottom: none;
}

@media (max-width: 768px) {
  .frequency__card-box {
    gap: 15px;
  }

  .frequency__card {
    max-width: 100%;
  }
}

@media (max-width: 580px) {
  .frequency__bck-svg {
    opacity: 0.2;
  }
}
