.auth-header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-header__logo > a {
  font-size: 1.1em;
}

.auth-header {
  position: relative;
}
