header {
  background-color: var(--prime-blue);
}

.header_unscrolled {
  overflow-y: scroll;
  z-index: 100;
}

.nav {
  display: flex;
  align-items: center;
}

.nav__ul {
  padding: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4em;
  font-size: var(--header-font);
  color: #fff;
}

.nav__li {
  /* margin-right: 4em; */
  cursor: pointer;
}

.nav__li:last-of-type {
  margin-right: 0;
}

.logo > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px;
  background: var(--darker-blue);
  color: #fff;
  font-size: 1.05em;
  border-radius: 0 0 15px 15px;
  transition: var(--transition);
}

.logo:hover > a {
  color: var(--prime-yellow);
}

.logo__img {
  width: 36px;
  margin-top: 8px;
}

.logo > a > span {
  font-weight: 400;
}

.nav__li:after {
  display: block;
  content: '';
  border-bottom: solid 2px var(--light-grey);
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  transition: transform 230ms ease-in-out;
  top: 5px;
  position: relative;
}

.nav__li_logo:after {
  display: none;
}

.nav__li:hover:after {
  transform: scaleX(1.1);
  -webkit-transform: scaleX(1.1);
}

.nav__li_active:after {
  transform: scaleX(1.1);
  -webkit-transform: scaleX(1.1);
}

.nav__acc-btn {
  color: #fff;
  font-weight: 300;
  min-width: 110px;
  padding: 8px 10px;
  background: var(--darker-blue);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.nav__acc-btn:hover {
  color: var(--prime-yellow);
}

.nav__acc-btn > svg {
  margin-right: 7px;
}

.burger-cont {
  display: none;
  justify-content: center;
  /* width: 90%; */
  align-items: center;
  position: relative;
  z-index: 3;
  margin: auto;
}

@media (max-width: 860px) {
  .header {
    height: 100%;
  }

  .nav {
    visibility: hidden;
    background: #000000c9;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    z-index: 2;
    height: 100%;
    width: 100%;
    top: -100%;
    position: absolute;
    gap: 3em;
    opacity: 0;
    transition: all 0.3s;
  }

  .nav_active {
    top: 0;
    opacity: 1;
    visibility: visible;
    overflow-y: scroll;
  }

  .nav__ul {
    flex-direction: column;
    gap: 3.5em;
    margin-top: 160px;
  }

  .burger-cont {
    display: flex;
  }

  li.nav__li_logo {
    display: none;
  }

  .logo > a {
    font-size: 1.1em;
  }
}
