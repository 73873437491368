.promo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: var(--main-gap); */

  /* min-height: 100vh; */
  min-height: calc(100vh - 108px);
  color: #fff;
  background: var(--prime-blue); /* #376068 */ /* #37636c */

  /* animation: fade-in 0.5s; */
}

.promo__img {
  max-height: 300px;
}

.promo__container {
  position: relative;
  padding: 0 10px;
  animation: fade-in 0.7s;
}

.promo__svg {
  position: absolute;
  /*  fill: #ffd98717; */
  fill: var(--darker-blue);
  z-index: 0;
}

.promo__svg_right {
  top: -10%;
  right: -11%;
}

.promo__svg_left {
  bottom: 10%;
  left: -11%;
}

.promo__container p {
  font-size: 1.4em;
}

.promo__container > h1 {
  font-weight: 400;
  font-size: 4em;
  letter-spacing: 5px;
  position: relative;
  z-index: 1;
}

.promo__container > h1 > span {
  color: var(--prime-yellow);
}

.promo__add-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 1;
}

.promo__add-info > div {
  font-size: 1.4em;
  display: flex;
}

.promo__add-info > div > span {
  margin-left: 20px;
}

.promo__add-info > div > span:first-of-type {
  margin-left: 0;
}

.promo__btn {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 5px 0 0;
  background: 0;
  border: 0;
  font-weight: 300;
  color: var(--black-secodary);
  cursor: pointer;
}

.promo__btn > p {
  margin-right: 7px;
  font-size: 1.2em;
  transition: var(--transition);
  border-bottom: 1px solid transparent;
}

.promo__btn:hover > p {
  color: #000;
  border-bottom: 1px solid #000;
}

.arrow {
  cursor: pointer;
  position: relative;
  width: 19px;
  height: 13px;

  &::before {
    transform: rotate(-135deg);
  }

  &::after {
    transform: rotate(135deg);
  }
}

.arrow::before,
.arrow::after {
  content: '';
  left: 8px;
  width: 1.7px;
  height: 9px;
  position: absolute;
  border-bottom: 10.7px solid var(--black-secodary);
  transition: transform 0.2s ease;
}

.promo__btn_active .arrow::before {
  transform: translateY(-8px) rotate(-45deg);
}

.promo__btn_active .arrow::after {
  transform: translateY(-8px) rotate(45deg);
}

@media (max-width: 960px) {
  .promo__container > h1 {
    text-align: center;
    font-size: 3.5em;
  }
}

@media (max-width: 860px) {
  .promo {
    min-height: calc(100vh - 102px);
  }
}

@media (max-width: 580px) {
  .promo__container > h1 {
    font-size: 2.5em;
  }

  .promo__container p {
    font-size: 1.1em;
  }

  .promo__add-info > div {
    font-size: 1.1em;
  }

  .promo__svg {
    width: 150px;
    height: 150px;
  }

  .promo__add-info > div > span {
    margin-left: 10px;
  }
}

@media (max-width: 370px) {
  .promo__container > h1 {
    font-size: 2em;
  }

  .promo__container p {
    font-size: 1em;
  }

  .promo__add-info > div {
    font-size: 1em;
  }
}
